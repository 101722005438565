import React, { Component } from 'react';
import Header from 'layouts/components/header';

function withHeader(Comp) {
  return class extends Component {
    render() {
      return (
        <>
          <Header />
          <Comp />
        </>
      );
    }
  };
}

export default withHeader;
