import featureApi from 'api/featureApi';
import stellar_api from 'api/stellar_api';
import * as CONTANTS from 'constants/index';
import {
  BRIDGE_TOKEN_ABI,
  MULTICHAIN_NETWORK_SWAP,
  MULTICHAIN_SUPPORT_ID,
} from 'constants/multichain';
import { useUIContext } from 'pages/ui-context';
import { useRef } from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { getTokenDecimalEthereum } from 'ultis';
import { getMultiTokenDecimalEthereum } from 'ultis';
import {
  toWeiStr,
  formWei,
  getMultiLineFromCache,
  isNativeToken,
  storeToCache,
  deepCompare,
} from 'ultis';
import { getMulticallContract } from 'ultis/ultis.contract';

export const convertRound = (numberString, decimal = 5) => {
  numberString = numberString + '';

  numberString = numberString.replace(/[\uff01-\uff6e\uff61]/g, function (ch) {
    return String.fromCharCode(ch.charCodeAt(0) - 0xfee0);
  });
  let charCode = numberString.charCodeAt(numberString.length - 1);
  if (charCode === 12290 || charCode === 129) {
    numberString = numberString.replace(/.$/, '.');
  }
  if (decimal === 0) {
    numberString = numberString.replace(/[^0-9]/g, '');
  } else {
    numberString = numberString.replace(/[^0-9.]/g, '');
  }
  if (numberString.length === 0) return 0;
  let index = numberString.indexOf('.');
  if (index > 0) {
    let string = numberString.slice(index + 1);
    string = string.replace(/[^0-9]/g, '');
    let value = parseInt(numberString.slice(0, index));
    if (string.length > decimal) {
      string = string.slice(0, decimal);
    }
    numberString = value + '.' + string;
  } else if (index === 0) {
    let string = numberString.slice(index + 1);
    string = string.replace(/[^0-9]/g, '');
    let value = 0;
    if (string.length > decimal) {
      string = string.slice(0, decimal);
    }
    numberString = value + '.' + string;
  } else {
    numberString = parseInt(numberString);
  }
  return numberString;
};

export const useGetTokenBalance = ({
  asset,
  getBalanceDelay = 100,
  refreshTime = 3000,
  stopInterval,
}) => {
  const { web3Instance, address, userIsConnected, networkMatch, network } =
    useUIContext();
  const [balance, setBalance] = useState(0);
  const [refresh, setRefresh] = useState(false);
  const tokenABI = useMemo(() => MULTICHAIN_NETWORK_SWAP[1].bridgeTokenABI, []);

  const getBalanceStellar = async () => {
    try {
      setRefresh(false);
      const account = await stellar_api.getAccount(address);
      const isNative = network.nativeCurrency.symbol === asset.name;
      if (account?.balances.length) {
        if (!isNative) {
          const matchAsset = account.balances.find(
            (e) =>
              asset.address === e.asset_issuer && asset.name === e.asset_code
          );
          if (matchAsset) {
            const balanceToWei = toWeiStr(
              matchAsset.balance,
              CONTANTS.STELLAR_DECIMAL
            );
            const balance = formWei(balanceToWei, CONTANTS.STELLAR_DECIMAL);
            setBalance(balance);
            setRefresh(true);
            return;
          }
          setBalance(0);
          setRefresh(true);
          return;
        }
        const native_asset = account.balances.find(
          (e) => e.asset_type === 'native'
        );
        const balanceToWei = toWeiStr(
          native_asset.balance,
          CONTANTS.STELLAR_DECIMAL
        );
        const balance = formWei(balanceToWei, CONTANTS.STELLAR_DECIMAL);
        setBalance(balance);
        setRefresh(true);
        return;
      }
      setBalance(0);
    } catch (error) {
      setBalance(0);
      setRefresh(true);
    }
  };

  const getBalanceEthereum = async () => {
    try {
      setRefresh(false);
      if (isNativeToken(asset.address)) {
        const balance = await web3Instance.eth.getBalance(address);
        setBalance(web3Instance.utils.fromWei(balance, 'ether'));
        setRefresh(true);
        return;
      }
      let decimals = asset.decimals.from;
      if (decimals < 0) {
        const [res, getDecimalsError] = await getTokenDecimalEthereum({
          web3Instance,
          chain: network.networkSignature,
          token: { address: asset.address, symbol: asset.name },
        });
        if (res) decimals = res.tokenDecimal;
      }
      const tokenContract = await new web3Instance.eth.Contract(
        tokenABI,
        asset.address
      );
      const tokenBalance = await tokenContract.methods
        .balanceOf(address)
        .call();
      const balanceConvert = formWei(tokenBalance, decimals);
      setBalance(balanceConvert);
      setRefresh(true);
    } catch (error) {
      setBalance(0);
      setRefresh(true);
    }
  };

  useEffect(() => {
    setBalance(0);
    if (userIsConnected && networkMatch) {
      switch (network.chain) {
        case 'stellar': {
          if (asset.name) {
            const timeout = setTimeout(() => {
              getBalanceStellar();
            }, [getBalanceDelay]);
            return () => clearTimeout(timeout);
          }
          break;
        }
        default: {
          if (asset.address.length && web3Instance) {
            const timeout = setTimeout(() => {
              getBalanceEthereum();
            }, [getBalanceDelay]);
            return () => clearTimeout(timeout);
          }
          break;
        }
      }
    }
    setRefresh(false);
  }, [
    userIsConnected,
    networkMatch,
    network,
    asset,
    web3Instance,
    getBalanceDelay,
  ]);

  useEffect(() => {
    let mount = true;
    if (!refresh || stopInterval) return;
    switch (network.chain) {
      case 'stellar': {
        const reset = setTimeout(() => {
          if (mount) getBalanceStellar();
        }, [refreshTime]);
        return () => {
          mount = false;
          clearTimeout(reset);
        };
      }
      default: {
        const reset = setTimeout(() => {
          if (mount) getBalanceEthereum();
        }, [refreshTime]);
        return () => {
          mount = false;
          clearTimeout(reset);
        };
      }
    }
  }, [refresh, stopInterval, refreshTime]);

  return useMemo(
    () => ({
      balance,
    }),
    [balance]
  );
};

export const useWindowResizeMobile = (toValue = 575) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < toValue);

  useEffect(() => {
    let windowResizeListener = window.addEventListener('resize', () => {
      setIsMobile(window.innerWidth < toValue);
    });
    return () => window.removeEventListener('resize', windowResizeListener);
  }, []);

  return isMobile;
};

export const usePrevious = (value) => {
  const currentRef = useRef(value);
  const previousRef = useRef();
  if (currentRef.current !== value) {
    previousRef.current = currentRef.current;
    currentRef.current = value;
  }
  return previousRef.current;
};

export const useNetworkMatch = (wallet, network) => {
  const [networkMatch, setNetworkMatch] = useState(true);
  const [walletNetwork, setWalletNetwork] = useState(null);
  const checkNetwork = async () => {
    try {
      const inUsedNetwork = await wallet.request.network();
      setWalletNetwork(inUsedNetwork);
      if (network?.decimal == inUsedNetwork || network?.key == inUsedNetwork) {
        setNetworkMatch(true);
      } else setNetworkMatch(false);
    } catch (error) {
      setNetworkMatch(false);
    }
  };
  useEffect(() => {
    if (wallet && network) {
      checkNetwork();
      const reset = setInterval(checkNetwork, 3000);
      return () => clearInterval(reset);
    }
  }, [wallet, network]);
  return { networkMatch, walletNetwork };
};

export const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

export const useGetListTokenPair = () => {
  const [listTokenPairs, setListTokenPairs] = useState([]);
  const [loading, setLoading] = useState(false);
  const getListToken = useCallback(async () => {
    try {
      setLoading(true);
      const tokenPairResult = await featureApi.getTokenPair();
      if (deepCompare(listTokenPairs, tokenPairResult))
        setListTokenPairs(tokenPairResult);
      setLoading(false);
    } catch (error) {
      setListTokenPairs([]);
      setLoading(false);
    }
  }, [listTokenPairs]);

  useEffect(() => getListToken(), []);

  useEffect(() => {
    if (loading) return;
    const reset = setInterval(() => {
      getListToken();
    }, 300000);
    return () => clearInterval(reset);
  }, [listTokenPairs, loading]);

  return { listTokenPairs };
};

export const useGetTokenDecimal = ({ listTokenPairs, userIsConnected }) => {
  const origin = window.location.origin;
  const [retry, setRetry] = useState({
    isRetry: false,
    data: {},
    func: () => {},
  });

  const getFetchList = useCallback(async (network, listToken) => {
    let listFetching = [];
    const listKey = listToken.map(
      (item) => `${item.from_symbol}/${item.from_chain}`
    );
    const [listCached, multiCacheError] = await getMultiLineFromCache(
      CONTANTS.DECIMALS_CACHES,
      origin,
      listKey
    );
    if (multiCacheError) return [null, multiCacheError];
    for (const item of listToken) {
      const matchData = listCached[`${item.from_symbol}/${item.from_chain}`];
      if (!matchData) {
        listFetching.push(item);
        continue;
      }
      if (matchData.decimals === -1) {
        listFetching.push(item);
        continue;
      }
      if (network.decimal) {
        if (
          network.decimal !== matchData.chain_id ||
          item.from_address !== matchData.address
        ) {
          listFetching.push(item);
        }
        continue;
      }
    }
    return [listFetching, null];
  }, []);

  const multicallDecimalEthereum = useCallback(async (network, listToken) => {
    if (network.chain === 'stellar') {
      return;
    }
    try {
      const [listFetching] = await getFetchList(network, listToken); // get token not on list from cache
      if (!listFetching?.length) {
        return;
      }
      const callData = listFetching.map((item) => ({
        reference: item.from_symbol,
        contractAddress: item.from_address,
        abi: BRIDGE_TOKEN_ABI[network.networkSignature],
        calls: [
          {
            reference: 'decimals',
            methodName: 'decimals',
            methodParameters: [],
          },
        ],
      }));
      const multicall = getMulticallContract(network.networkSignature);
      if (!multicall) {
        const [success, failList, error] = await getMultiTokenDecimalEthereum({
          chain: network.networkSignature,
          listToken: listFetching,
        });

        const retryPayload = {
          isRetry: true,
          data: {
            chain: network.networkSignature,
          },
          func: getMultiTokenDecimalEthereum,
        };
        if (failList?.length) {
          retryPayload.data.listToken = failList;
          setRetry(retryPayload);
          return;
        }
        if (error) {
          retryPayload.data.listToken = listFetching;
          setRetry(retryPayload);
          return;
        }
        return;
      }

      const { results } = await multicall.call(callData);
      //caching data
      for (const [key, value] of Object.entries(results)) {
        const payload = {
          address: value.originalContractCallContext.contractAddress,
          chain_id: network.decimal,
          chain: network.networkSignature,
          symbol: key,
          decimals: value.callsReturnContext[0].returnValues[0],
        };
        await storeToCache(
          CONTANTS.DECIMALS_CACHES,
          `${key}/${network.networkSignature}`,
          payload
        );
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  const cachingTokenDecimals = useCallback(async (network, listToken) => {
    try {
      const listTokenMatch = listToken.filter(
        (item) =>
          item.from_chain === network?.networkSignature &&
          !isNativeToken(item.from_address)
      );

      const nativeToken = listToken.find(
        (item) =>
          item.from_chain === network?.networkSignature &&
          isNativeToken(item.from_address)
      );
      if (nativeToken)
        await storeToCache(
          CONTANTS.DECIMALS_CACHES,
          `${nativeToken.from_symbol}/${network?.networkSignature}`,
          {
            address: nativeToken.from_address,
            chain_id: network.decimal,
            chain: network.networkSignature,
            symbol: nativeToken.from_symbol,
            decimals: 18,
          }
        );
      if (listTokenMatch.length)
        await multicallDecimalEthereum(network, listTokenMatch);
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    if (!listTokenPairs || !userIsConnected) return;
    const networks_from = [
      ...new Set(listTokenPairs.map((item) => item.from_chain)),
    ];
    const networks_to = [
      ...new Set(listTokenPairs.map((item) => item.to_chain)),
    ];
    const networks = [...new Set(networks_from.concat(networks_to))];
    networks.forEach((networkName) => {
      const network = MULTICHAIN_SUPPORT_ID[networkName];
      cachingTokenDecimals(network, listTokenPairs);
    });
  }, [listTokenPairs, userIsConnected]);

  useEffect(() => {
    if (retry.isRetry) {
      const reset = setInterval(async () => {
        const [success] = await retry.func(retry.data);
        if (success) setRetry({ ...retry, isRetry: false });
      }, 3000);
      return () => clearInterval(reset);
    }
  }, [retry]);
};
