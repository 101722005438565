import Modal from 'antd/lib/modal/Modal';
import PropTypes from 'prop-types';
import React from 'react';
import { ToAbsoluteUrl } from 'ultis';
import './style.scss';
import { Col, Row } from 'antd';
import { MULTICHAIN_SUPPORT_ID, NETWORK_ORDER } from 'constants/multichain';
import { Image } from 'components';
import { useMemo } from 'react';
ModalSelectNetwork.propTypes = {
  visible: PropTypes.bool,
  title: PropTypes.string,
  onCancel: PropTypes.func,
};

ModalSelectNetwork.defaultProps = {
  visible: false,
  title: 'Select a network',
};

const ListNetworkSelect = ({
  index,
  data,
  isCurrentNetworkSelected,
  onClick,
  disableNetwork,
}) => {
  const handleClick = () => {
    onClick(data);
  };
  return (
    <li
      onClick={handleClick}
      className={`pt-md pb-md pl-xl pr-xl ${
        data.isSupport && !disableNetwork ? 'li-hover' : 'li-disable'
      }`}
    >
      <Row
        justify='space-between'
        style={{ width: '100%' }}
        className={`network-item ${
          !data.isSupport || disableNetwork ? 'disable-select' : ''
        }`}
      >
        <Col className='network-item__des' span={20}>
          <Image
            className='mr-xsm image'
            errorClass='image--error'
            src={ToAbsoluteUrl(data?.icon)}
            alt=''
          />
          <span className='headline6 mt-3xsm text-color-title net-name'>
            {data?.networkName}
          </span>
        </Col>
        <Col span={4} className='item__checked'>
          <span>
            {isCurrentNetworkSelected ? (
              <Image
                className='image'
                errorClass='image--error'
                src='/images/icon/icon-checked-regular.svg'
              />
            ) : null}
          </span>
        </Col>
      </Row>
    </li>
  );
};

function ModalSelectNetwork({
  visible,
  title,
  onCancel,
  currentNetwork,
  onSelected,
  disableNetwork,
  ...props
}) {
  const handleSelected = (e) => {
    onSelected(e);
    onCancel();
  };
  const renderNetwork = useMemo(
    () =>
      NETWORK_ORDER.map((networkName, index) => {
        const network = MULTICHAIN_SUPPORT_ID[networkName];
        return (
          <ListNetworkSelect
            key={`${index}_${network.name}`}
            data={network}
            isCurrentNetworkSelected={currentNetwork?.name === network.name}
            onClick={handleSelected}
            disableNetwork={disableNetwork?.name === network.name}
          />
        );
      }),
    [currentNetwork, disableNetwork, handleSelected]
  );

  return (
    <Modal
      {...props}
      visible={visible}
      title={title}
      centered
      closeIcon={
        <Image
          className='btn-close'
          errorClass='btn-close--error'
          src={ToAbsoluteUrl('/images/icon/icon-close-btn.svg')}
        />
      }
      onCancel={onCancel}
      className='modal modal-select-network'
      footer={null}
    >
      <ul>{renderNetwork}</ul>
    </Modal>
  );
}

export default ModalSelectNetwork;
