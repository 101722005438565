import React from 'react';
import { Button } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import PropTypes from 'prop-types';
import { ToAbsoluteUrl } from 'ultis';
import './style.scss';

ModalRejected.propTypes = {
  visible: PropTypes.bool,
  title: PropTypes.string,
  onCancel: PropTypes.func,
};

ModalRejected.defaultProps = {
  visible: false,
  // title: 'Confirm',
};

function ModalRejected({ visible, title, message, onCancel, ...props }) {
  return (
    <Modal
      {...props}
      title={title}
      visible={visible}
      centered
      closeIcon={<img src={ToAbsoluteUrl('/images/icon/icon-close-btn.svg')} />}
      onCancel={onCancel}
      className={`modal modal-rejected error `}
      footer={[
        <Button
          className={`dismiss headline6`}
          key='submit'
          type='default'
          block
          onClick={onCancel}
        >
          Dismiss
        </Button>,
      ]}
    >
      <div
        className={`nofitication
            }`}
      >
        <div className={`nofitication__image`}>
          <span>
            <img src={ToAbsoluteUrl('/images/icon/icon-error.svg')} alt={'error'} />
          </span>
        </div>
        <p>{message ? message : 'Transaction rejected'}</p>
      </div>
    </Modal>
  );
}

export default ModalRejected;
