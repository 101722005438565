import React from 'react';
import { useEffect } from 'react';
import { useCallback } from 'react';
import { useState } from 'react';
import './index.scss';

const Image = ({ errorClass, ErrorComp, className, src, ...props }) => {
  const [error, setError] = useState(false);
  const handleError = useCallback(() => setError(true), []);
  useEffect(() => {
    if (src) setError(false);
  }, [src]);
  return error ? (
    ErrorComp ? (
      <ErrorComp
        className={`image image--error ${className || ''} ${errorClass || ''}`}
      />
    ) : (
      <span
        className={`image image--error ${className || ''} ${errorClass || ''}`}
        {...props}
      ></span>
    )
  ) : (
    <img
      className={`image ${className || ''}`}
      src={src}
      {...props}
      onError={handleError}
    />
  );
};

export default Image;
