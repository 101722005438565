import * as CONSTANTS from './index';

const MULTICHAIN_NETWORK_SWAP = {
  [CONSTANTS.CONVERT_NETWORK_TYPE.from_eth]: {
    bridgeTokenABI: CONSTANTS.BridgeToken_ETH,
    bridgeBankABI: CONSTANTS.Bridge_ETH,
    bridgeBankAddress: CONSTANTS.CONTRACT_ADDRESS.bridgeETH,
  },
  [CONSTANTS.CONVERT_NETWORK_TYPE.from_bsc]: {
    bridgeTokenABI: CONSTANTS.BridgeToken_BSC,
    bridgeBankABI: CONSTANTS.Bridge_BSC,
    bridgeBankAddress: CONSTANTS.CONTRACT_ADDRESS.bridgeBSC,
  },
  [CONSTANTS.CONVERT_NETWORK_TYPE.from_stellar]: {
    bridgeBankAddress: CONSTANTS.CONTRACT_ADDRESS.bridgeStellar,
  },
  [CONSTANTS.CONVERT_NETWORK_TYPE.from_evry]: {
    bridgeTokenABI: CONSTANTS.BridgeToken_EVRY,
    bridgeBankABI: CONSTANTS.Bridge_EVRY,
    bridgeBankAddress: CONSTANTS.CONTRACT_ADDRESS.bridgeEVRYNET,
  },
};

const MULTICHAIN_SUPPORT_SCAN_LINK = {
  eth: process.env.REACT_APP_ETHERSCAN_LINK,
  bsc: process.env.REACT_APP_BSCSCAN_LINK,
  stellar: process.env.REACT_APP_STELLARSCAN_LINK,
  evrynet: process.env.REACT_APP_EVRYNETSCAN_LINK,
};

const MULTICHAIN_SUPPORT_ID = {
  eth: {
    chain: 'ethereum',
    networkName: 'Ethereum Network',
    altName: 'Ethereum',
    networkSignature: 'eth',
    icon: '/images/icon/icon-ethereum-currency.svg',
    nativeCurrency: {
      name: 'ETH',
      symbol: 'ETH',
      decimals: 18,
    },
    ...CONSTANTS.NETWORK_ID[process.env.REACT_APP_REQUIRE_ETH_NETWORK],
    isSupport: !!process.env.REACT_APP_REQUIRE_ETH_NETWORK,
  },
  bsc: {
    chain: 'ethereum',
    networkName: 'Binance Smart Chain Network',
    altName: 'Binance',
    networkSignature: 'bsc',
    icon: '/images/icon/icon-binance-currency.svg',
    nativeCurrency: {
      name: 'BNB',
      symbol: 'BNB',
      decimals: 18,
    },
    rpcUrls: [process.env.REACT_APP_BSC_PROVIDER],
    blockExplorerUrls: [process.env.REACT_APP_BSCSCAN_LINK],
    ...CONSTANTS.NETWORK_ID[process.env.REACT_APP_REQUIRE_BSC_NETWORK],
    isSupport: !!process.env.REACT_APP_REQUIRE_BSC_NETWORK,
  },
  evrynet: {
    chain: 'ethereum',
    altName: 'Evry Net',
    networkName: 'Evrynet Network',
    networkSignature: 'evrynet',
    icon: '/images/icon/icon-evrynet-currency.svg',
    nativeCurrency: {
      name: 'EVRY',
      symbol: 'EVRY',
      decimals: 18,
    },
    rpcUrls: [process.env.REACT_APP_EVRYNET_PROVIDER],
    blockExplorerUrls: [process.env.REACT_APP_EVRYNETSCAN_LINK],
    ...CONSTANTS.NETWORK_ID[process.env.REACT_APP_REQUIRE_EVERYNET_NETWORK],
    isSupport: !!process.env.REACT_APP_REQUIRE_EVERYNET_NETWORK,
  },
  stellar: {
    chain: 'stellar',
    altName: 'Stellar',
    networkName: 'Stellar Network',
    networkSignature: 'stellar',
    icon: '/images/icon/icon-stellar-currency.svg',
    nativeCurrency: {
      name: 'XLM',
      symbol: 'XLM',
      decimals: CONSTANTS.STELLAR_DECIMAL,
    },
    rpcUrls: null,
    blockExplorerUrls: [process.env.REACT_APP_STELLARSCAN_LINK],
    ...CONSTANTS.NETWORK_ID[process.env.REACT_APP_REQUIRE_STELLAR_NETWORK],
    isSupport: !!process.env.REACT_APP_REQUIRE_STELLAR_NETWORK,
  },
  reiTestnet: {
    chain: 'ethereum',
    altName: 'REI Testnet',
    networkName: 'REI Testnet',
    networkSignature: 'rei-testnet',
    icon: '/images/icon/icon-rei-currency.svg',
    nativeCurrency: {
      name: 'TREI',
      symbol: 'TREI',
      decimals: 18,
    },
    rpcUrls: [process.env.REACT_APP_REITESTNET_PROVIDER],
    blockExplorerUrls: [process.env.REACT_APP_REITESTNETSCAN_LINK],
    ...CONSTANTS.NETWORK_ID[process.env.REACT_APP_REQUIRE_REITESTNET_NETWORK],
    isSupport: !!process.env.REACT_APP_REQUIRE_REITESTNET_NETWORK,
  },
  nova: {
    chain: 'ethereum',
    altName: 'Nova',
    networkName: 'Nova',
    networkSignature: 'nova',
    icon: '/images/icon/icon-nova-currency.svg',
    nativeCurrency: {
      name: 'NOVA',
      symbol: 'NOVA',
      decimals: 18,
    },
    rpcUrls: [process.env.REACT_APP_NOVA_PROVIDER],
    blockExplorerUrls: [process.env.REACT_APP_NOVASCAN_LINK],
    ...CONSTANTS.NETWORK_ID[process.env.REACT_APP_REQUIRE_NOVA_NETWORK],
    isSupport: !!process.env.REACT_APP_REQUIRE_NOVA_NETWORK,
  },
};

export const NETWORK_ORDER = ['nova'];

export const BRIDGE_TOKEN_ABI = {
  eth: CONSTANTS.BridgeToken_ETH,
  bsc: CONSTANTS.BridgeToken_BSC,
  evrynet: CONSTANTS.BridgeToken_EVRY,
};

export {
  MULTICHAIN_SUPPORT_SCAN_LINK,
  MULTICHAIN_SUPPORT_ID,
  MULTICHAIN_NETWORK_SWAP,
};
