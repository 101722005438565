import { useMemo } from 'react';
import { useHistory, withRouter } from 'react-router';

const pages = ['/history', '/proof-of-assets', '/documents'];

function GuestGuard({ children }) {
  let history = useHistory();
  let url = history.location.pathname;

  return useMemo(() => {
    if (url === '/' || pages.some((_) => url.includes(_))) {
      return <>{children}</>;
    }
    return null;
  }, [children, url]);
}

export default withRouter(GuestGuard);
