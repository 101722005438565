import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Input, Modal, Button } from 'antd';
import { useLocation } from 'react-router-dom';
import {
  ModalConnectNetwork,
} from 'components';
import { MULTICHAIN_SUPPORT_ID, NETWORK_ORDER } from 'constants/multichain';
import { useUIContext } from 'pages/ui-context';
import { submitFaucet, ToAbsoluteUrl } from 'ultis';

export const NETWORK_LIST = MULTICHAIN_SUPPORT_ID;
const DEFAULT_FROM_NETWORK = NETWORK_LIST.evrynet;
const DEFAULT_TO_NETWORK = NETWORK_LIST.evrynet;

const FAUCET_ADDRESS = process.env.REACT_APP_FAUCET_ADDRESS

const FormFaucet = () => {
  const { search } = useLocation()
  const { address, network, userIsConnected, isMobile, web3Instance, walletNetwork } =
    useUIContext();
  const [errorModal, setErrorModal] = useState(null);
  const [successModal, setSuccessModal] = useState(null);
  const [fromNetwork, setFromNetwork] = useState(DEFAULT_FROM_NETWORK);
  const [toNetwork, setToNetwork] = useState(DEFAULT_TO_NETWORK);

  const [walletConnect, setWalletConnect] = useState(false);
  const [availableBalance, setAvailableBalance] = useState(null);

  const pageQuery = useMemo(() => {
    const query = new URLSearchParams(search);
    return {
      callback: query.get('callback'),
      autoFaucet: query.get('auto-faucet') === 'true',
    };
  }, [search]);

  const refetchBalance = useCallback(async () => {
    if (!web3Instance) {
      return
    }
    const balance = await web3Instance.eth.getBalance(FAUCET_ADDRESS);
    setAvailableBalance(web3Instance.utils.fromWei(balance, 'ether'))
  }, [web3Instance])

  useEffect(() => {
    refetchBalance()
  }, [refetchBalance])

  const onCancelWallet = useCallback(() => {
    setWalletConnect(false);
  }, []);


  const handleSubmit = useCallback(async () => {
    try {
      const [_, error] = await submitFaucet({
        web3Instance,
        walletAddress: address,
        chainId: Number(network.decimal),
      })
      if (error) {
        throw error
      }
      setSuccessModal('NOVA transferred to your wallet!')
      refetchBalance()
    } catch (error) {
      console.log(error)
      setErrorModal(error.message || error || 'Internal Error')
    }
  }, [address, network.decimal, refetchBalance, web3Instance]);

  useEffect(() => {
    const fetch = async () => {
      if (pageQuery.autoFaucet && userIsConnected && walletNetwork === network.decimal) {
        if (pageQuery.callback) {
          const balanceWei = await web3Instance.eth.getBalance(address)
          const balance = Number(web3Instance.utils.fromWei(balanceWei, 'ether'))
          console.log('balance', balance, typeof balance)
          if (balance > 0.5) {
            window.location.replace(pageQuery.callback)
            return
          }
        }

        handleSubmit()
      }
    }
    fetch()
  }, [pageQuery.autoFaucet, address, network.decimal, userIsConnected, walletNetwork])

  useEffect(() => {
    if (network) {
      const fromNet = NETWORK_LIST[network?.networkSignature];
      setFromNetwork(fromNet);
    }
  }, [network]);

  useEffect(() => {
    if (network) {
      // if to net, from net being duplicate.
      if (toNetwork?.name && fromNetwork?.name && toNetwork?.name === fromNetwork?.name) {
        const newNetwork = NETWORK_ORDER.find(
          (e) => e !== fromNetwork?.networkSignature && NETWORK_LIST[e].isSupport
        );
        setToNetwork(NETWORK_LIST[newNetwork]);
      }
    }
  }, [fromNetwork]);

  const handleSuccessClose = useCallback(() => {
    if (pageQuery.callback) {
      window.location.replace(pageQuery.callback)
    }
    setSuccessModal(null)
  }, [pageQuery.callback])
  const handleErrorClose = useCallback(() => {
    if (!errorModal.includes('User denied') && pageQuery.callback) {
      window.location.replace(pageQuery.callback)
    }
    setErrorModal(null)
  }, [pageQuery.callback, errorModal])

  return (
    <>
      <div className='form-swap'>
        <div className='form-container'>
          <div className='_fs-10 _fw-600 _mgt-6 _mgt-7:sm'>Nova Faucet</div>
        </div>
        <div className="lo-12 _g-6 _mgt-7">
          {/* <div className="nm-field">
            <label className="_fs-4 _fw-600">Available Balance</label>
            <div className="_fs-7 _fw-600 _cl-primary _mgt-3 _wb-bw">
              {availableBalance ? `${availableBalance} NOVA` : '-'}
            </div>
          </div> */}
          <div className="nm-field _mgt-4">
            <label className="_fs-4 _fw-600">
              Wallet Address
            </label>
            <Input
              className='select-token-group__amount u-input-style'
              type='text'
              disabled
              value={userIsConnected ? address : 'Please connect your wallet.'}
            />
          </div>
          <div className="_dp-f _jtfct-ct _mgt-6 _mgbt-6">
            {userIsConnected ? (
              <button className="nm-button is-size-large is-pill is-fluid" type="button" onClick={handleSubmit}>
                Submit
              </button>
            ) : (
              <>
                <div className='swap-button'>
                  <button
                    className='nm-button is-size-large is-pill is-fluid'
                    onClick={() => setWalletConnect(true)}
                    style={{ cursor: 'pointer' }}
                  >
                    {isMobile ? 'Connect Wallet' : 'Connect to Wallet'}
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      </div>

      <Modal
        visible={errorModal}
        title="Error"
        centered
        onCancel={handleErrorClose}
        className='modal modal-normal'
        closeIcon={<img src={ToAbsoluteUrl('/images/icon/icon-close-btn.svg')} alt="close-button" />}
        footer={
          <Button
            onClick={handleErrorClose}
            block
            className='modal-normal-dismiss__close-btn'
          >
            Close
          </Button>}
      >

        <img
          src={ToAbsoluteUrl(
            '/images/icon/icon-error.svg'
          )}
          alt=''
        />
        <h6 style={{ width: '100%', textAlign: 'center' }}>
          {errorModal}
        </h6>
      </Modal>
      <Modal
        visible={successModal}
        title="Success"
        centered
        onCancel={handleSuccessClose}
        className='modal modal-normal'
        closeIcon={<img src={ToAbsoluteUrl('/images/icon/icon-close-btn.svg')} alt="close-button" />}
        footer={
          <Button
            onClick={handleSuccessClose}
            block
            className='modal-normal-dismiss__close-btn'
          >
            Close
          </Button>}
      >

        <img
          src={ToAbsoluteUrl(
            '/images/icon/icon-checked.svg'
          )}
          alt=''
        />
        <h6 style={{ width: '100%', textAlign: 'center' }}>
          {successModal}
        </h6>
      </Modal>

      <ModalConnectNetwork visible={walletConnect} onCancel={onCancelWallet} />
    </>
  );
};

export default FormFaucet;
