import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'antd/lib/modal/Modal';
import { Button, Tooltip } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import { isMobile } from 'react-device-detect';
import { Link } from 'react-router-dom';
import featureApi from 'api/featureApi';
import { convertRound } from 'hook/ultis.hook';
import {
  checkNativeCurrency,
  formWei,
  sendContract,
  ToAbsoluteUrl,
  toWeiStr,
  truncateAddressMid,
  truncateEnd,
} from 'ultis';
import './style.scss';
import BigNumber from 'bignumber.js';
import { Image } from 'components';
import { zeroCutter } from 'ultis';

ModalConfirm.propTypes = {
  visible: PropTypes.bool,
  // title: PropTypes.string,
  onCancel: PropTypes.func,
  transactionInfor: PropTypes.object,
};

ModalConfirm.defaultProps = {
  visible: false,
  // title: 'Confirm',
};

const STATTUS = {
  _: 'Confirm',
  approving: 'Waiting for Confirmation',
  error: 'Error',
  sending: 'Transasction Submitted',
};

function ModalConfirm({
  web3Instance,
  wallet,
  visible,
  transactionInfor,
  contractInfor,
  onCancel,
  pushUpAgain,
  onSuccess,
  onConfirm,
  onError,
  ...props
}) {
  const {
    address,
    destinationAddress,
    assetChoosen,
    fromNetwork,
    toNetwork,
    amount = 0,
    tokenAddress,
  } = transactionInfor;
  const [termAgree, setTermAgree] = useState(false);
  const [status, setStatus] = useState(STATTUS._);
  const [protocolFee, setProtocolFee] = useState(0);

  const [loading, setLoading] = useState(false);
  const [allowance, setAllowance] = useState(false);
  const [allowanceError, setAllowanceError] = useState({
    visible: false,
    message: '',
  });

  const onQuotaPassAllow = useCallback(() => {
    setAllowance(true);
    setAllowanceError({ ...allowanceError, visible: false });
    setLoading(false);
  }, []);

  const onQuotaFailAllow = useCallback((message) => {
    setAllowance(false);
    setAllowanceError({
      visible: true,
      message,
    });
    setLoading(false);
  }, []);

  const getQuotaAllowance = useCallback(
    async (asset) => {
      setLoading(true);
      try {
        const allowance = await Promise.all([
          await featureApi.checkDailySwap({
            address,
            amount,
            symbol: asset.name,
          }),
          await featureApi.getProtocolFee({
            toSymbol: asset.name,
            chainName: toNetwork.networkSignature,
            amount: amount,
          }),
        ]);
        if (allowance.every((e) => e.success)) {
          setProtocolFee(allowance[1].fee);
          onQuotaPassAllow();
          return;
        }
        setProtocolFee(0);
        if (!allowance[0]?.success) {
          onQuotaFailAllow(
            'You have reach maxium daily quota, please try again later'
          );
          return;
        }
        onQuotaFailAllow(
          'Your amount to transfer is smaller than protocol fee is used for this transaction, please try another'
        );
      } catch (error) {
        // console.log(error);
        setProtocolFee(0);
        onQuotaFailAllow(
          'You have reach maxium daily quota or something has happen'
        );
      }
    },
    [address, amount, toNetwork.networkSignature]
  );

  const handleCancel = useCallback(() => {
    if (onCancel) onCancel();
    setTermAgree(false);
    setStatus(STATTUS._);
    setAllowance(false);
    setLoading(false);
    setAllowanceError({ ...allowanceError, visible: false });
  }, [onCancel]);

  const handleAgreeTerm = useCallback(() => {
    setTermAgree(!termAgree);
  }, [termAgree]);
  const handleConfirm = async () => {
    try {
      const checkNativeCurr = checkNativeCurrency(
        assetChoosen.name,
        fromNetwork?.nativeCurrency?.name
      );
      if (onConfirm) onConfirm();
      setStatus(STATTUS.approving);
      await sendContract(wallet.chain, {
        web3Instance,
        walletAddress: address,
        bridgeBank_ABI: contractInfor?.bridgeBankABI
          ? contractInfor.bridgeBankABI
          : null,
        bridgeBankAddress: contractInfor?.bridgeBankAddress
          ? contractInfor.bridgeBankAddress
          : null,
        asset: assetChoosen,
        tokenAddress,
        tokenConvertStr: assetChoosen.convertString,
        tokenDecimal: assetChoosen.decimals.from,
        fromNetwork: fromNetwork,
        toNetwork: toNetwork.networkSignature,
        targetAddr: destinationAddress,
        amountToken: amount,
        checkNativeCurr,
        wallet,
      });

      pushUpAgain();
      if (onSuccess) onSuccess();
      setStatus(STATTUS.sending);
    } catch (err) {
      console.log(err);
      pushUpAgain();
      if (onError) onError();
      setStatus(STATTUS.error);
    }
  };
  const statusBase = useMemo(() => {
    let res = {
      modalTitle: '',
      modalClassName: '',
      nofiImage: '',
      nofiImageAlt: '',
      statusDescribe: null,
      footerBtn: null,
    };
    switch (status) {
      case STATTUS.approving: {
        res.modalTitle = STATTUS.approving;
        res.modalClassName = 'loading';
        res.nofiImage = '/images/icon/icon-loading.svg';
        res.nofiImageAlt = 'loading';
        res.statusDescribe = (
          <p className='approve-loading__text'>
            Confirm this transaction in your wallet
          </p>
        );
        break;
      }
      case STATTUS.sending: {
        res.modalTitle = STATTUS.sending;
        res.modalClassName = 'txSubmited';
        res.nofiImage = '/images/icon/icon-transaction-submitted.svg';
        res.nofiImageAlt = 'transaction submitted';
        res.statusDescribe = (
          <Link className='swap-loading__link headline6' to='/history'>
            View on History
          </Link>
        );
        res.footerBtn = (
          <Button
            className={`success headline6`}
            key='submit'
            type='default'
            block
            onClick={handleCancel}
          >
            Dismiss
          </Button>
        );
        break;
      }
      case STATTUS.error: {
        res.modalTitle = STATTUS.error;
        res.modalClassName = 'error';
        res.nofiImage = '/images/icon/icon-error.svg';
        res.nofiImageAlt = 'error';
        res.statusDescribe = <p>Transaction rejected</p>;
        res.footerBtn = (
          <Button
            className={`dismiss headline6`}
            key='submit'
            type='default'
            block
            onClick={handleCancel}
          >
            Dismiss
          </Button>
        );
        break;
      }
      default: {
        res.modalTitle = STATTUS._;
        res.footerBtn = (
          <Button
            key='submit'
            className={`${
              !termAgree || !allowance || loading ? 'disable' : ''
            } headline6`}
            disabled={!termAgree || !allowance || loading}
            type='default'
            block
            onClick={handleConfirm}
          >
            {loading ? 'Calculating ...' : 'Confirm'}
          </Button>
        );
        break;
      }
    }
    return res;
  }, [status, termAgree, allowance, loading]);

  const renderProtocolFee = useMemo(() => {
    const decimals =
      assetChoosen?.decimals?.to < 0 ? 18 : assetChoosen?.decimals?.to;
    return amount && visible ? (
      <span className='token headline6'>
        {`~ ${zeroCutter(
          convertRound(
            decimals ? protocolFee : formWei(protocolFee, decimals),
            decimals > 8 ? 8 : decimals
          )
        )} ${assetChoosen?.name}`}
      </span>
    ) : (
      0
    );
  }, [assetChoosen, amount, protocolFee, visible]);
  const renderAmount = useMemo(() => {
    if (amount && visible) {
      const decimals =
        assetChoosen?.decimals?.to < 0 ? 18 : assetChoosen?.decimals?.to;
      const amountConvert = new BigNumber(toWeiStr(amount, decimals));
      const protocolFeeConvert = new BigNumber(toWeiStr(protocolFee, decimals));
      const res = amountConvert.minus(protocolFeeConvert).toString();
      console.log(
        'rawdata: amount, protocolFee, decimals',
        amount,
        protocolFee,
        decimals
      );
      console.log('amountConvert', protocolFeeConvert);
      console.log('protocolFeeConvert', protocolFeeConvert);

      if (res.includes('-')) {
        onQuotaFailAllow(
          'This transaction will not success due to transaction fee is greater than your amount'
        );
      }
      return (
        <div>
          <Image src={ToAbsoluteUrl(toNetwork.icon)} alt='' />
          <span>~</span>
          {/* note */}
          <span className='token headline6 ml-xsm'>{`${
            res.includes('-') ? '-' : ''
          }${zeroCutter(
            convertRound(
              decimals ? formWei(res, decimals) : res,
              decimals > 8 ? 8 : decimals
            )
          )} ${assetChoosen?.name}`}</span>
        </div>
      );
    }
    return 0;
  }, [amount, assetChoosen, toNetwork, protocolFee, visible]);

  useEffect(() => {
    setProtocolFee(0);
    if (visible && amount && assetChoosen.address && address) {
      getQuotaAllowance(assetChoosen);
    }
  }, [visible, amount, assetChoosen, address]);

  return (
    <Modal
      {...props}
      title={statusBase.modalTitle}
      visible={visible}
      centered
      closeIcon={<img src={ToAbsoluteUrl('/images/icon/icon-close-btn.svg')} />}
      onCancel={handleCancel}
      className={`modal modal-confirm ${statusBase.modalClassName}`}
      footer={[statusBase.footerBtn]}
    >
      {status === STATTUS._ ? (
        <>
          {amount ? (
            <Tooltip
              title={amount.toString().length > 10 ? amount : ''}
              placement='top'
            >
              <h3>{`${amount ? truncateEnd(amount.toString(), 8) : ''} ${
                assetChoosen.name
              }`}</h3>
            </Tooltip>
          ) : null}

          <div className='intro'>
            <div className='from'>
              <p>From</p>
              <div className='show border-from'>
                <Image src={ToAbsoluteUrl(fromNetwork?.icon)} alt='' />
                <p className='show__text'>{fromNetwork?.networkName}</p>
              </div>
            </div>
            <ArrowRightOutlined />
            <div className='to'>
              <p>To</p>
              <div className='show border-to'>
                <Image src={ToAbsoluteUrl(toNetwork?.icon)} alt='' />
                <p className='show__text'>{toNetwork?.networkName}</p>
              </div>
            </div>
          </div>

          <div className='description'>
            <div className='item'>
              <span className='headline6'>Asset:</span>
              <div>
                {assetChoosen.img ? (
                  <Image src={ToAbsoluteUrl(assetChoosen.img)} alt='' />
                ) : (
                  <span className='default-image'>
                    <span className='default-image__text'>
                      {assetChoosen.name}
                    </span>
                  </span>
                )}
                <span className='headline6'>{assetChoosen.name}</span>
              </div>
            </div>

            <div className='item'>
              <span>Destination:</span>
              <div>
                <img
                  src={ToAbsoluteUrl(toNetwork?.icon)}
                  alt={toNetwork.altName}
                />
                <span>
                  {isMobile
                    ? truncateAddressMid(destinationAddress, 6, 6)
                    : truncateAddressMid(destinationAddress, 8, 8)}
                </span>
              </div>
            </div>

            <div className='item'>
              <span className='headline6'>Protocol Fee:</span>
              {renderProtocolFee}
            </div>

            <div className='item'>
              <span className='headline6'>You will receive:</span>
              {renderAmount}
            </div>

            <span className='headline6'>
              Please initiate a single transfer , we will only monitor the first
              transfer
            </span>
          </div>

          <Checkbox
            disabled={loading || !allowance}
            onChange={handleAgreeTerm}
            checked={termAgree}
          >
            <span className='bodyLarge checkbox-content'>
              I have read and agree to the{' '}
              <Link to='/documents/term_of_service' target='_blank'>
                Terms of Use
              </Link>
            </span>
          </Checkbox>
          {allowanceError.visible ? (
            <div className='allowance-warning py-2xsm'>
              <span className='allowance-warning__text bodyLarge'>
                {allowanceError.message}
              </span>
            </div>
          ) : null}
        </>
      ) : (
        <div
          className={`nofitication ${
            status === STATTUS.approving ? 'approve-loading' : 'swap-loading'
          }`}
        >
          <div
            className={`nofitication__image ${
              status === STATTUS.approving ? 'loading' : ''
            }`}
          >
            <span>
              <img
                src={ToAbsoluteUrl(statusBase.nofiImage)}
                alt={statusBase.nofiImageAlt}
              />
            </span>
          </div>
          {statusBase.statusDescribe}
        </div>
      )}
    </Modal>
  );
}

export default ModalConfirm;
