import axiosClient from './axiosClient';

const featureApi = {
  submitFaucet(data) {
    const url = 'faucet/submit';
    return axiosClient.post(url, { ...data });
  },
};

export default featureApi;
