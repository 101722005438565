import React from 'react';
import {
  HashRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';
import { EvryHubI18nProvider, I18nProvider } from '../layouts/i18n';
import ErrorPage from './404page';
import EvryHub from './faucet';
import FormFaucet from './faucet/components/form-faucet';
import UIProvider from './ui-context';

function App() {
  return (
    <EvryHubI18nProvider>
      <I18nProvider>
        <UIProvider>
          <Router>
            <Switch>
              <Route exact path='/' component={EvryHub} />
              <Route exact path='/form-faucet' component={FormFaucet} />
              <Route path='/not-found' component={ErrorPage} />
              <Redirect to='/not-found' />
            </Switch>
          </Router>
        </UIProvider>
      </I18nProvider>
    </EvryHubI18nProvider>
  );
}

export default App;
