import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { Button, Dropdown, Menu } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import './index.scss';

const CustomDropdown = ({
  isLoading,
  onSelectItem,
  listItem,
  selected,
  className,
  dropDownClass,
  renderItem,
  dropdownIcon,
  ...props
}) => {
  const [selectedItem, setSelectedItem] = useState(selected);

  useEffect(() => setSelectedItem(selected), [selected]);

  const handleMenuClick = useCallback(
    async (e) => {
      const newSelectedItem = listItem.find((item) => item.key === e.key);
      if (isLoading) return;
      if (onSelectItem) {
        const [_, error] = await onSelectItem(newSelectedItem);
        // setSelectedItem(newSelectedItem);
        if (error) {
          setSelectedItem(selected);
          return;
        }
        return;
      }
      setSelectedItem(newSelectedItem);
    },
    [onSelectItem, isLoading, listItem]
  );

  const renderMenu = useMemo(() => {
    return (
      <div className='overlay__drop-down-menu'>
        <Menu
          className={`overlay__drop-down-menu--wrapper ${
            dropDownClass ? dropDownClass : ''
          }`}
          onClick={handleMenuClick}
          selectedKeys={selectedItem?.key}
        >
          {listItem &&
            listItem.map((item) => (
              <Menu.Item key={item.key}>
                {renderItem ? renderItem(item) : item.value}
              </Menu.Item>
            ))}
        </Menu>
      </div>
    );
  }, [selectedItem, listItem, renderItem, handleMenuClick]);

  return (
    <Dropdown overlay={renderMenu} trigger={['click']} {...props}>
      <Button className={`nm-button is-variant-secondary _bdrd-max ${className ? className : ''}`}>
        {renderItem ? (
          renderItem(selectedItem)
        ) : (
          <span>{selectedItem.value}</span>
        )}
        <div className='button__arrowDown'>
          {dropdownIcon ? dropdownIcon : <DownOutlined />}
        </div>
      </Button>
    </Dropdown>
  );
};

export default CustomDropdown;
