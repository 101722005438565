import { Multicall } from 'ethereum-multicall';
import { MULTICHAIN_SUPPORT_ID } from 'constants/multichain';
import { web3HttpInstance } from '.';

export const getMulticallContract = (network) => {
  switch (network) {
    case 'evrynet':
      return null;
    default:
      const matchWeb3Instance = web3HttpInstance[network];
      const customAddress =
        MULTICHAIN_SUPPORT_ID[network].multicallContractAddress;
      return customAddress
        ? new Multicall({
            web3Instance: matchWeb3Instance,
            multicallCustomContractAddress: customAddress,
            tryAggregate: true,
          })
        : new Multicall({
            web3Instance: matchWeb3Instance,
            tryAggregate: true,
          });
  }
};
