import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Col, Row, Tooltip } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import { Image } from 'components';
import {
  DECIMALS_CACHES,
  DEFAULT_TOKEN_ICON,
  STELLAR_DECIMAL,
} from 'constants/index';
import {
  convertRound,
  useGetTokenBalance,
  useWindowResizeMobile,
} from 'hook/ultis.hook';
import { useUIContext } from 'pages/ui-context';
import PropTypes from 'prop-types';
import {
  getSingleLineFromCache,
  getTokenDecimalEthereum,
  getTokenImageAndName,
  isNativeToken,
  ToAbsoluteUrl,
} from 'ultis';
import './style.scss';

ModalSelectToken.propTypes = {
  visible: PropTypes.bool,
  title: PropTypes.string,
  onCancel: PropTypes.func,
};

ModalSelectToken.defaultProps = {
  visible: false,
  title: 'Select a Token',
  // from_Network?.networkSignature: 'bsc',
  // to_Network: 'eth',
};

const ListTokenItem = ({
  visible,
  data,
  isCurrentAsset,
  onClick,
  isMobileScreen,
}) => {
  const [tooltipEnable, setTooltipEnable] = useState(visible);
  const { balance: tokenBalance } = useGetTokenBalance({
    asset: data,
    refreshTime: 10000,
    stopInterval: !isCurrentAsset || !visible,
  });
  useEffect(() => setTooltipEnable(true));

  const handleClick = useCallback(() => {
    setTooltipEnable(false);
    onClick();
  }, [onClick]);

  const TokenName = ({ tooltipEnable, data }) => {
    const [tooltipVisible, setTooltipVisible] = useState(false);
    const title = useMemo(
      () => (`${data.detail}`.length > 10 ? data.detail : ''),
      [data]
    );
    const handleTooltipEnable = useCallback(() => {
      setTooltipVisible(true);
    }, []);
    const handleTooltipdisable = useCallback(() => {
      setTooltipVisible(false);
    }, []);
    return (
      <Tooltip
        visible={tooltipEnable && tooltipVisible && !!title.length}
        title={title}
        placement='right'
      >
        <Col
          span={isMobileScreen ? 12 : 14}
          onMouseEnter={handleTooltipEnable}
          onMouseLeave={handleTooltipdisable}
          onBlur={handleTooltipdisable}
        >
          <Image className='mr-xsm' src={ToAbsoluteUrl(data.img)} alt='' />
          <span className={`headline6 mt-3xsm text-color-title`}>
            {data.name}{' '}
          </span>
          <span className={`bodyLarge mt-3xsm text-color-text detail-token`}>
            / {data.detail}
          </span>
        </Col>
      </Tooltip>
    );
  };

  const TokenBalance = ({ tooltipEnable, data, tokenBalance }) => {
    const [tooltipVisible, setTooltipVisible] = useState(false);
    const title = useMemo(
      () => (`${tokenBalance}`.length > 10 ? tokenBalance : ''),
      [tokenBalance]
    );
    const renderTokenBalance = useMemo(
      () =>
        !!tokenBalance && data.decimals
          ? convertRound(
            tokenBalance,
            isMobileScreen
              ? 3
              : data.decimals.from > 8
                ? 8
                : data.decimals.from
          )
          : '0',
      [tokenBalance, isMobileScreen, data]
    );
    const handleTooltipEnable = useCallback(() => {
      setTooltipVisible(true);
    }, []);
    const handleTooltipdisable = useCallback(() => {
      setTooltipVisible(false);
    }, []);
    return (
      <Tooltip
        visible={tooltipEnable && tooltipVisible && !!title.length}
        title={title}
        placement='bottom'
      >
        <Col
          span={8}
          className='label'
          onMouseEnter={handleTooltipEnable}
          onMouseLeave={handleTooltipdisable}
          onBlur={handleTooltipdisable}
        >
          <span className={`headline6 mt-3xsm text-color-title label__text`}>
            {renderTokenBalance}
          </span>
        </Col>
      </Tooltip>
    );
  };

  return (
    <li
      onClick={handleClick}
      className='pt-md pb-md pl-xl pr-xl list-token__item'
    >
      <Row justify='space-between'>
        <TokenName tooltipEnable={tooltipEnable} data={data} />
        <TokenBalance
          tooltipEnable={tooltipEnable}
          data={data}
          tokenBalance={tokenBalance}
        />
        <Col span={2} className='item__checked'>
          {isCurrentAsset ? (
            <Image src='/images/icon/icon-checked-regular.svg' />
          ) : null}
        </Col>
      </Row>
    </li>
  );
};

function ModalSelectToken({
  visible,
  title,
  onCancel,
  from_Network,
  to_Network,
  currentAsset,
  onDoneLoadToken,
  onLoadingToken,
  ...props
}) {
  const { listTokenPairs, network, userIsConnected, wallet } = useUIContext();
  const [listToken, setListToken] = useState([]);
  const [listTokenDefault, setListTokenDefault] = useState([]);

  const isMobileScreen = useWindowResizeMobile(720);

  const origin = window.location.origin;

  const handleOnSearch = (e) => {
    if (!e.target.value) setListToken(listTokenDefault);
    const newListToken = listTokenDefault.filter(
      (item) =>
        item?.detailToken
          ?.trim()
          .toLowerCase()
          .includes(e.target.value.trim().toLowerCase()) ||
        item?.name
          ?.trim()
          .toLowerCase()
          .includes(e.target.value.trim().toLowerCase())
    );
    setListToken(newListToken);
  };

  const handleChooseAsset = useCallback(
    (item) => {
      props.getAsset(item);
      onCancel();
    },
    [props.getAsset]
  );

  const getListUnique = () => [
    ...new Set(listTokenPairs.map((item) => item.from_symbol)),
  ];

  const getListTokenDecimals = async ({
    listTokenPairs,
    listSymbol,
    listImage,
    listDetailName,
  }) => {
    try {
      let newListToken = [];
      for (const tokenName of listSymbol) {
        // find pair
        const pair = listTokenPairs.find(
          (item) =>
            tokenName === item.from_symbol &&
            from_Network.networkSignature === item.from_chain &&
            to_Network?.networkSignature === item.to_chain
        );
        if (!pair) continue;
        const payload = {
          name: pair.from_symbol,
          img: listImage[pair.from_symbol] || DEFAULT_TOKEN_ICON,
          detail: listDetailName[pair.from_symbol],
          address: pair.from_address,
          min: pair.min_amount,
          max: pair.max_amount,
          decimals: { from: -1, to: -1 },
        };
        if (pair.from_chain === 'stellar')
          payload.address = payload.address.toUpperCase();
        if (userIsConnected) {
          // get token decimal
          const pairData = {
            from: {
              address: pair.from_address,
              chain: pair.from_chain,
              symbol: pair.from_symbol,
            },
            to: {
              address: pair.to_address,
              chain: pair.to_chain,
              symbol: pair.to_symbol,
            },
          };
          for (const [key, value] of Object.entries(pairData)) {
            const matchKey = `${value.symbol}/${value.chain}`;
            // if chain is stellar decimal is equal to 7
            if (value.chain === 'stellar') {
              payload.decimals[key] = STELLAR_DECIMAL;
              continue;
            }
            // get data from cache
            const [cachingDecimals, cacheError] = await getSingleLineFromCache(
              DECIMALS_CACHES,
              origin,
              matchKey
            );
            // if exist in cache
            if (cachingDecimals) {
              payload.decimals[key] = cachingDecimals?.decimals;
              continue;
            }
            // if not exist, get and store to cache
            const [tokenDecimalRes, getDecimalError] =
              await getTokenDecimalEthereum({
                chain: pairData[key].chain,
                token: {
                  address: pairData[key].address,
                  symbol: pairData[key].symbol,
                },
              });
            if (tokenDecimalRes)
              payload.decimals[key] = tokenDecimalRes.tokenDecimal;
          }
        }
        if (
          isNativeToken(pair.from_address) ||
          from_Network?.nativeCurrency?.symbol === pair.from_symbol
        ) {
          newListToken.push(newListToken[0]);
          newListToken[0] = payload;
        } else {
          newListToken.push(payload);
        }
      }
      return newListToken;
    } catch (error) {
      return [];
    }
  };

  const getImage = async () => {
    try {
      onLoadingToken();
      setListToken([]);
      const unique = getListUnique();
      const { listImage, listDetailName } = await getTokenImageAndName();
      const newListToken = await getListTokenDecimals({
        listTokenPairs,
        listSymbol: unique,
        listImage,
        listDetailName,
      });
      if (JSON.stringify(listTokenDefault) == JSON.stringify(newListToken)) {
        setListToken(newListToken);
        onDoneLoadToken();
        return [1, null];
      }
      setListToken(newListToken);
      setListTokenDefault(newListToken);
      onDoneLoadToken();
      return [1, null];
    } catch (error) {
      console.log(error);
      onDoneLoadToken();
      return [null, error];
    }
  };

  const renderListToken = useMemo(
    () =>
      listToken.map((item, index) => (
        <ListTokenItem
          visible={visible}
          key={`${item.name}_${index}`}
          data={item}
          isMobileScreen={isMobileScreen}
          isCurrentAsset={currentAsset.name === item.name}
          onClick={() => handleChooseAsset(item)}
        />
      )),
    [listToken, isMobileScreen, currentAsset, handleChooseAsset, visible]
  );
  useEffect(() => {
    // change data on listTokenPairs has updated, from net, to net changed
    // change data on current network changed, user connnected, wallet changed
    if (
      listTokenPairs.length &&
      from_Network?.networkSignature && to_Network?.networkSignature &&
      from_Network?.networkSignature !== to_Network?.networkSignature &&
      network?.networkSignature === from_Network?.networkSignature
    ) {
      getImage();
    }
  }, [
    userIsConnected,
    listTokenPairs,
    from_Network,
    to_Network,
    wallet,
    network,
  ]);

  useEffect(() => {
    if (listTokenDefault?.length) {
      const tokenCurrent = listTokenDefault.find((item) => {
        return item.name === currentAsset.name;
      });
      if (tokenCurrent) {
        props.getAsset(tokenCurrent);
        return;
      }
      const token = listTokenDefault.filter((item) => {
        return (
          item.name === from_Network.nativeCurrency.symbol ||
          isNativeToken(item.address)
        );
      })[0];
      if (token) props.getAsset(token);
      else {
        props.getAsset(listTokenDefault[0]);
      }
      return;
    }
    props.resetAsset(); // note
  }, [listTokenDefault, userIsConnected, wallet, network]);

  return (
    <Modal
      {...props}
      visible={visible}
      title={title}
      centered
      closeIcon={
        <Image
          className='btn-close'
          errorClass='btn-close--error'
          src={ToAbsoluteUrl('/images/icon/icon-close-btn.svg')}
        />
      }
      onCancel={onCancel}
      className='modal modal-select-tokenn'
      footer={null}
    >
      <div className='search rounded-md'>
        <Image
          className='search__img'
          errorClass='search__img--error'
          src={ToAbsoluteUrl('/images/icon/icon-search.svg')}
          alt=''
        />
        <input
          placeholder='Search name or paste address'
          onChange={handleOnSearch}
        />
      </div>
      {listToken?.length ? (
        <ul className='list-token'>{renderListToken}</ul>
      ) : (
        <div className='spin-cfoundontainer'>
          <Image
            className='spinning'
            errorClass='spinning--error'
            src={ToAbsoluteUrl('/images/icon/icon-not-found.svg')}
            alt=''
          />
          <span className='headline6'>Not Found</span>
        </div>
      )}
    </Modal>
  );
}

export default ModalSelectToken;
